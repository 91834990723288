import React, {useEffect, useState} from "react"
import {Redirect, Route, useHistory, useParams} from "react-router"
import './index.css';
import styled from "@emotion/styled";
import axios from "axios";
import {getConfig} from "../../AppConfig";
import {IonBackdrop, IonButton, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonToast} from "@ionic/react";
import {createOutline} from "ionicons/icons";
import Text from "../CommonStyles/Text";
import CurrencyInput from "react-currency-input-field";

interface Params {
  eventcode: string
  id: string
}

export const Input = styled("input")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`;

const Select = styled("select")`
  display: flex;
  justify-content: space-between;
  box-sizing: border-box;
  height: 60px;
  border-radius: 10px;
  background-color: #e0ebf180;
  outline: none;
  border: 1px solid #66666660;
  padding-left: 15px;
  ::placeholder {
    font-size: 14px;
    padding-left: 0px;
  }
`

const Option = styled("option")`
  box-sizing: border-box;
  background-color: #0000000d;
  outline: none;
  border: none;
  ::placeholder {
    font-size: 14px;
    padding-left: 4px;
  }
`
const Purchaser: React.FC = () => {
  const history = useHistory()
  const params = useParams<Params>();
  const { apiUrl } = getConfig()

  const storeguestUrl = `${apiUrl}/storeguest`

  const eventcode = params.eventcode
  const eventguid = params.id

  const [toastState, setToastState] = useState({display: false, goback: false, message: ''})
  const [groupName, setGroupName] = useState('')
  const [groupModal, setGroupModal] = React.useState({active: false, groupName: '', groupId: ""})
  const [messageModal, setMessageModal] = React.useState({active: false, message: '', title: ''})
  const [guestModal, setGuestModal] = React.useState({active: false, groupName: '', guestId: "", guestName: ""})
  const [registrationId, setRegistrationId] = React.useState('')
  const [guestDefaultData, setGuestDefaultData] = React.useState({})
  const [fieldListData, setFieldListData] = React.useState({guestlist: [], guestfieldslist:[], guestlookuplist: [], selectedregistrationid: null})
  const [overrideEdit, setOverrideEdit] = React.useState(true)
  const [currentGuest, setCurrentGuest] = React.useState({guestid: 0})
  const [storeGroups, setStoreGroups] = React.useState([])
  const [errorData, setErrorData] = React.useState([])

  useEffect(() => {
    if (registrationId === 'add_new') {
      getStoreGuestData(currentGuest.guestid, true)
    }
    else if (registrationId) {
      getStoreGuestData(currentGuest.guestid, false)
    }
  }, [registrationId])

  useEffect(()=> {
    const getPurchaserData = async () => {

      const purchaserUrl = `${apiUrl}/purchaserregistration/` + eventguid
      const {data} = await axios.get(purchaserUrl)
      const parsedData = JSON.parse(data.jsonString)
      console.log(parsedData)
      localStorage.setItem('tokenstore', parsedData.token)
      setStoreGroups(parsedData?.groups)
    }

    getPurchaserData()
  }, [])

  useEffect(() => {
    setOverrideEdit(true)
    setRegistrationId('')
    setGuestDefaultData({})
    setFieldListData({guestlist: [], guestfieldslist:[], guestlookuplist: [], selectedregistrationid: null})
  }, [guestModal.active === false])

  useEffect(() => {
    var newValues = {}
    fieldListData.guestfieldslist.map(field => {
      const fieldValue = "fieldvalue" + field.fieldcontrol.toString()
      newValues[field.fieldid] = field[fieldValue] ? field[fieldValue] : ''
    })
    setGuestDefaultData(newValues)
  }, [fieldListData.guestfieldslist])

  const getGroupItems = () => {
    if (storeGroups && storeGroups.length > 0) {
      return storeGroups?.map((storeGuest) => {
        if (storeGuest && storeGuest?.groupname?.length > 0) {
          const guestRows = storeGuest.guests?.map((guest) => {
            return (
                <>
                  <IonRow class={'ion-justify-content-center ion-align-items-center'}>
                    <IonCol class={'ion-justify-content-end'}>
                      <IonItem lines={'none'} style={{ marginLeft: '25px'}} onClick={() => {manageGuest(guest, storeGuest)}}>
                        <IonButton id="notification-button" ion-button fill="clear">
                          <IonIcon
                              // slot="icon-only"
                              icon={createOutline}
                              style={{width: 20, height: 20, color: "#676767"}}
                          />
                        </IonButton>
                        <IonLabel style={{ marginLeft: '5px', fontSize: '16px'}}>{guest.guestname}</IonLabel>
                      </IonItem>
                    </IonCol>
                  </IonRow>
                </>
            )
          })
          return (
              <>
                <IonRow class={'ion-justify-content-center ion-align-items-center'}>
                  <IonCol>
                    <IonItem lines={'none'} onClick={() => {renameGroup(storeGuest)}}>
                      {storeGuest.allowchangegroupname &&
                          <IonButton id="notification-button" ion-button fill="clear" >
                            <IonIcon
                                // slot="icon-only"
                                icon={createOutline}
                                style={{width: 20, height: 20, color: "#676767"}}
                            />
                          </IonButton>
                      }
                      <IonLabel class={'ion-text-wrap'} style={{fontSize: '16px'}}>{storeGuest?.groupname}</IonLabel>
                    </IonItem>
                  </IonCol>
                </IonRow>

                {guestRows}
              </>
          )
          // return fullRowCol
        }
      })
    }
  }

  const putStoreGuestData = async (body) => {
    const token = localStorage.getItem('tokenstore')
    const storeguestUrl = `${apiUrl}/storeguest`

    const {data} = await axios.put(storeguestUrl, body,{headers: {
        'Authorization': `Bearer ${token}`
      }})
    const parsedData = JSON.parse(data.jsonString)
    if (parsedData.status === 'expired') {
      localStorage.removeItem("tokenstore")
      history.push(`/${eventcode}/event`);
    }
    else if (parsedData.status === 'success') {
      console.log("SUCCESS")
    }
  }

  const getStoreGroups = async () => {
    const body = {}
    const token = localStorage.getItem('tokenstore')

    const {data} = await axios.get(storeguestUrl, {headers: {
        'Authorization': `Bearer ${token}`
      }})
    const parsedData = JSON.parse(data.jsonString)
    if (parsedData.status === 'expired') {
      localStorage.removeItem("tokenstore")
      history.push(`/${eventcode}/event`);
    }
    else if (parsedData.status === 'success') {
      setStoreGroups(parsedData?.groups)
    }
  }

  const renameGroup = (guest) => {
    if (guest.allowchangegroupname) {
      setGroupName(guest.groupname)
      setGroupModal({active: true, groupName: guest.groupname, groupId: guest.groupid})
    }
  }

  const manageGuest = (guest, storeguest) => {
    console.log('Guest Manage')
    console.log(guest)
    setCurrentGuest(guest)
    getStoreGuestData(guest.guestid, false)
    setGuestModal({active: true, groupName: storeguest?.groupname, guestId: guest.guestid, guestName: guest.guestname})
  }

  const getStoreGuestData = async (storeguestid, addNew) => {
    if (storeguestid) {
      var body = {"guestid": storeguestid}

      if (addNew) {
        body['registrationid'] = -1
      }
      else if (Number(registrationId) >= 0) {
        body['registrationid'] = Number(registrationId)
      }

      const token = localStorage.getItem('tokenstore')
      const storeguestUrl = `${apiUrl}/storeguest`

      const {data} = await axios.post(storeguestUrl, body,{headers: {
          'Authorization': `Bearer ${token}`
        }})
      const parsedData = JSON.parse(data.jsonString)

      if (parsedData.status === 'expired') {
        localStorage.removeItem("tokenstore")
        history.push(`/${eventcode}/event`);
      }
      else if (parsedData.status === 'success') {
        var copyDataObject = {...parsedData}
        const copyGuestFieldsList = parsedData.guestfieldslist.map(guestFL => {
          return {...guestFL, "overridereadonly": guestFL.fieldreadonly}
        })
        copyDataObject['guestfieldslist'] = copyGuestFieldsList

        console.log(copyDataObject)
        setFieldListData(copyDataObject)
      }
    }

  }

  const renameGroupText = async () => {
    const body = {
      "groupid": groupModal.groupId,
      "groupname": groupName
    }
    const token = localStorage.getItem('tokenstore')

    const groupRenameUrl = `${apiUrl}/storeguestgroupname`
    const {data} = await axios.put(groupRenameUrl, body, {headers: {
        'Authorization': `Bearer ${token}`
      }})
    const parsedData = JSON.parse(data.jsonString)
    if (parsedData.status === 'expired') {
      localStorage.removeItem("tokenstore")
      history.push(`/${eventcode}/event`);
    }
    else if (parsedData.status === 'failure') {
      setMessageModal({active: true, message: parsedData.message, title: 'Error Saving Group Name'})
    }
    else {
      let storeGuest = storeGroups.find(
          (item) => item.groupid === groupModal.groupId
      )
      storeGuest.groupname = groupName
      setGroupModal({active: false, groupName: '', groupId:''})
    }
  }


  const getGroupRenameModal = () => {
    if (groupModal.active) {
      return (
          <>
            <IonBackdrop visible={groupModal.active}></IonBackdrop>
            <div id="box" style={{width: '80%', height: '50%'}}>
              <IonGrid>
                <IonRow>
                  <IonCol style={{ display: "flex" }} size="12" className="ion-justify-content-center" >
                    <div style={{ display: "flex", fontSize: '26px' }}>{"Rename Your Group"}</div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{display: "flex"}} size="12" className="ion-justify-content-center">
                    <div>{groupModal.groupName}</div>
                  </IonCol>
                </IonRow>

                <IonRow>
                  <IonCol>
                    <IonItem lines={'none'}>
                      <Input
                          id="group-name"
                          style={{width: '100%'}}
                          placeholder={groupModal.groupName}
                          value={groupName}
                          onChange={(e) => {
                            setGroupName(e.target.value)
                          }}
                      />
                      {/*<IonInput onIonInput={(e: any) => setGroupName(e.target.value)} style={{border: "1px solid #66666660"}} className="mi-outlined"  clearOnEdit value={groupName}></IonInput>*/}
                    </IonItem>
                  </IonCol>
                </IonRow>
                <IonRow style={{marginBottom: '20px'}}>
                  <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                    <IonButton onClick={() => { setGroupModal({active: false, groupName: '', groupId:''})}} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                      Cancel
                    </IonButton>
                  </IonCol>
                  <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                    <IonButton onClick={() => { renameGroupText() }} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                      Save
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </>
      )
    }
    else {
      return (
          <></>
      )
    }
  }


  const getMessageModal = () => {
    if (messageModal.active) {
      return (
          <>
            <IonBackdrop visible={messageModal.active}></IonBackdrop>
            <div id="box" style={{width: '50%', height: '30%'}}>
              <IonGrid>
                <IonRow style={{marginBottom: '20px'}}>
                  <IonCol style={{ display: "flex" }} size="12" className="ion-justify-content-center" >
                    <div style={{ display: "flex", fontSize: '26px', color: 'red' }}>{messageModal.title}</div>
                  </IonCol>
                </IonRow>
                <IonRow style={{marginBottom: '20px'}}>
                  <IonCol style={{display: "flex"}} size="12" className="ion-justify-content-center">
                    <div style={{ display: "flex", fontSize: '18px' }}>{messageModal.message}</div>
                  </IonCol>
                </IonRow>
                <IonRow style={{marginBottom: '20px'}}>
                  <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                    <IonButton onClick={() => { setMessageModal({active: false, message: '', title: ''})}} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                      Ok
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </>
      )
    }
    else {
      return (
          <></>
      )
    }
  }


  const getEditIcon = () => {
    if (fieldListData.selectedregistrationid > 0 && registrationId !== 'add_new') {
      return (
          <IonCol style={{ paddingLeft: "10px", paddingRight: "0px", display: "flex"}} size="1" className="ion-justify-content-left" >
            <IonButton id="notification-button" ion-button fill="clear" onClick={(e) => {setOverrideEdit(!overrideEdit)}}>
              <IonIcon
                  // slot="icon-only"
                  icon={createOutline}
                  style={{width: 30, height: 30, color:  overrideEdit ? "#d00909" : '#59c75e' }}
              >
              </IonIcon>
            </IonButton>
          </IonCol>
      )
    }
    else {
      return (
          <></>
      )
    }
  }
  const getFieldOptions = (guestData) => {

    // console.log(guestData[fieldValue])

    // const dataObj = guestDefaultData
    // var newObject = {...dataObj}
    // newObject[guestData.fieldid] = fieldValue
    // setGuestDefaultData(newObject)

    // console.log(guestData)
    // console.log(fieldListData.guestfieldslist)
    const fieldValue = "fieldvalue" + guestData.fieldcontrol.toString()

    var options = [<></>]

    if (fieldListData && fieldListData?.guestlookuplist && fieldListData?.guestlookuplist.length > 0) {
      const filteredOptions = fieldListData?.guestlookuplist?.filter(guests => guests.lookupfieldid === guestData.fieldid)

      // console.log(filteredOptions)
      if (filteredOptions && filteredOptions.length > 0) {
        options = filteredOptions.map((guestListDataOption, index) => {
          return (
              <Option value={guestListDataOption?.lookupid} key={index}>
                {guestListDataOption?.lookup}
              </Option>
          )
        })
      }
    }


    return options
  }

  const submitData = async () => {
    setErrorData([])

    // Check for errors on required fields
    var fieldData = []
    var errorArray = []
    var error = false
    fieldListData?.guestfieldslist?.map((guestData) => {
      const fieldValueString = "fieldvalue" + guestData.fieldcontrol
      var dataToAppend = {"fieldid":guestData.fieldid}
      if (guestData.fieldrequired && !guestDefaultData[guestData.fieldid]) {
        errorArray.push({fieldid: guestData.fieldid, message: guestData.fieldlabel + ' is required.'})
        error = true
      }
      if (guestDefaultData[guestData.fieldid]) {
        dataToAppend[fieldValueString] = guestDefaultData[guestData.fieldid]
        fieldData.push(dataToAppend)
      }
    })

    if (!error) {
      var body = {"guestfieldslist": fieldData, "guestid": guestModal.guestId}

      if (registrationId !== 'add_new' && Number(registrationId) > 0) {
        body['registrationid'] = Number(registrationId)
      }
      else if (registrationId === 'add_new') {
        body['registrationid'] = -1
      }

      await putStoreGuestData(body)
      getStoreGroups()
      setGuestModal({active: false, groupName: '', guestId: '', guestName: ''})
    }
    else {
      setErrorData(errorArray)
    }
  }
  const updateGuestData = (value, guestData) => {
    const dataObj = guestDefaultData
    var newObject = {...dataObj}
    newObject[guestData.fieldid] = value
    setGuestDefaultData(newObject)

    const item = errorData.find(item => item.fieldid === guestData.fieldid)
    if (item && value) {
      const newError = errorData.filter(removeItem => removeItem.fieldid !== guestData.fieldid)
      setErrorData(newError)
    }
    else if (guestData.fieldrequired && !value) {
      const errorDataCopy = errorData
      errorDataCopy.push({fieldid: guestData.fieldid, message: guestData.fieldlabel + ' is required.'})
      setErrorData(errorDataCopy)
    }
  }
  const getErrorDataObject = (fieldid) => {
    return errorData.find(errorControl5 => errorControl5.fieldid === fieldid)
  }

  const getGuestModal = () => {
    if (guestModal.active) {
      return (
          <>
            <IonBackdrop visible={guestModal.active}></IonBackdrop>
            <div id="box" style={{display: "flex"}}>
              <IonGrid style={{height: "100%"}}>
                <IonRow>
                  <IonCol style={{ display: "flex" }} size="12" className="ion-justify-content-center" >
                    <div style={{ display: "flex", fontSize: '26px' }}>{"Edit Guest Information"}</div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{display: "flex", fontSize: '18px'}} size="12" className="ion-justify-content-center">
                    <div>{guestModal.groupName}</div>
                  </IonCol>
                </IonRow>
                <IonRow>
                  <IonCol style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size="12" className="ion-justify-content-left" >
                    <Select
                        name="guestlist"
                        id="id"
                        style={{width: '100%'}}
                        value={registrationId}
                        onChange={(e) => {
                          console.log(e.currentTarget.value)
                          setRegistrationId(e.currentTarget.value)
                        }}
                    >
                      <Option value={'add_new'} key={'add_new'}>
                        {'Add New'}
                      </Option>
                      <Option disabled>—————————————</Option>
                      {fieldListData?.guestlist?.map((guestmapList, index) => {
                        return (
                            <Option value={guestmapList.existingguestid} key={index}>
                              {guestmapList.existingguestname}
                            </Option>
                        )
                      })}
                    </Select>
                  </IonCol>
                </IonRow>
                {/*<IonRow>*/}
                {/*    <IonCol style={{ padding: "30px",display: "flex", fontSize: '22px'}} size="12" className="ion-justify-content-left">*/}
                {/*        <div>or add new guest</div>*/}
                {/*    </IonCol>*/}
                {/*</IonRow>*/}
                {fieldListData?.guestfieldslist?.map((guestData) => {
                  var editIcon = (<></>)
                  var size = "12"
                  if (guestData.fieldlabel === 'First Name' && fieldListData.selectedregistrationid > 0 && registrationId !== 'add_new') {
                    editIcon = getEditIcon()
                    size = "11"
                  }
                  const errorCheckObj = getErrorDataObject(guestData.fieldid)
                  if (guestData.fieldcontrol === 1) {
                    return (
                        <>
                          <IonRow>
                            {editIcon}
                            <IonCol style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex" }} size={size} className="ion-justify-content-right" >
                              <IonRow className="ion-no-padding" style={{width: '100%'}}>
                                <IonCol size={"12"} style={{width: '100%', display: "flex"}}>
                                  <Text style={{width: '100%'}} padding="8px" fontSize="14px" fontWeight="bold" color="#666666">{guestData.fieldlabel}</Text>
                                </IonCol>
                                <IonCol size={"12"} className="ion-justify-content-right" >
                                  <Select
                                      name={guestData.fieldlabel}
                                      id={guestData.fieldid}
                                      style={{width: '100%', display: "flex"}}
                                      required={guestData.fieldrequired}
                                      // value={state}
                                      // onChange={(e) => {
                                      //     setState(e.currentTarget.value)
                                      // }}
                                      // defaultValue={userData.addressInfo.state}
                                  >
                                    <Option value={'Yes'} key={1}>
                                      {'Yes'}
                                    </Option>
                                    <Option value={'No'} key={2}>
                                      {'No'}
                                    </Option>
                                  </Select>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          {errorCheckObj &&
                              <IonRow>
                                <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={size} className="ion-justify-content-left" >
                                  <span id="error">{errorCheckObj.message}</span>
                                </IonCol>
                              </IonRow>
                          }
                        </>
                    )
                  }
                  else if (guestData.fieldcontrol === 2) {
                    return (
                        <>
                          <IonRow>
                            {editIcon}
                            <IonCol style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex" }} size={size} className="ion-justify-content-right" >
                              <IonRow className="ion-no-padding" style={{width: '100%'}}>
                                <IonCol size={"12"} style={{width: '100%', display: "flex"}}>
                                  <Text style={{width: '100%'}} padding="8px" fontSize="14px" fontWeight="bold" color="#666666">{guestData.fieldlabel}</Text>
                                </IonCol>
                                <IonCol size={"12"} className="ion-justify-content-right" >
                                  <Select
                                      name={guestData.fieldlabel}
                                      id={guestData.fieldid}
                                      style={{width: '100%', display: "flex"}}
                                      required={guestData.fieldrequired}
                                      value={guestDefaultData[guestData.fieldid]}
                                      onChange={(e) => {updateGuestData(e.target.value, guestData)}}
                                  >
                                    {getFieldOptions(guestData)}
                                  </Select>
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          {errorCheckObj &&
                              <IonRow>
                                <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={size} className="ion-justify-content-left" >
                                  <span id="error">{errorCheckObj.message}</span>
                                </IonCol>
                              </IonRow>
                          }
                        </>
                    )
                  }
                  else if (guestData.fieldcontrol === 3) {
                    return (
                        <>
                          <IonRow>
                            {editIcon}
                            <IonCol style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex" }} size={size} className="ion-justify-content-right" >
                              <IonRow className="ion-no-padding" style={{width: '100%'}}>
                                <IonCol size={"12"} style={{width: '100%', display: "flex"}}>
                                  <Text style={{width: '100%'}} padding="8px" fontSize="14px" fontWeight="bold" color="#666666">{guestData.fieldlabel}</Text>
                                </IonCol>
                                <IonCol size={"12"} className="ion-justify-content-right" >
                                  <Input type="number"
                                         id={guestData.fieldid}
                                         placeholder={guestData.fieldlabel}
                                         required={guestData.fieldrequired}
                                         readOnly={guestData.fieldreadonly && guestData.overridereadonly}
                                         maxLength={guestData.fieldtextlength}
                                         value={guestDefaultData[guestData.fieldid]}
                                         onChange={(e) => {updateGuestData(e.target.value, guestData)}}
                                         name={guestData.fieldlabel} style={{width: '100%'}}
                                  />
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          {errorCheckObj &&
                              <IonRow>
                                <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={size} className="ion-justify-content-left" >
                                  <span id="error">{errorCheckObj.message}</span>
                                </IonCol>
                              </IonRow>
                          }
                        </>
                    )
                  }
                  else if (guestData.fieldcontrol === 4) {
                    return (
                        <>
                          <IonRow>
                            {editIcon}
                            <IonCol style={{ paddingLeft: "15px", paddingRight: "15px", display: "flex" }} size={size} className="ion-justify-content-right" >
                              <IonRow className="ion-no-padding" style={{width: '100%'}}>
                                <IonCol size={"12"} style={{width: '100%', display: "flex"}}>
                                  <Text style={{width: '100%'}} padding="8px" fontSize="14px" fontWeight="bold" color="#666666">{guestData.fieldlabel}</Text>
                                </IonCol>
                                <IonCol size={"12"} className="ion-justify-content-right" >
                                  <CurrencyInput
                                      id={guestData.fieldid}
                                      name={guestData.fieldlabel}
                                      style={{  width: '100%', display: 'flex', justifyContent: 'flex-end', boxSizing: 'border-box', height: '60px', borderRadius: '10px',
                                        textAlign: 'right', backgroundColor: "#e0ebf180", outline: 'none', border: "1px solid #66666660", paddingRight: '10px', paddingLeft: '15px'}}
                                      placeholder={guestData.fieldlabel}
                                      prefix="$"
                                      maxLength={guestData.fieldtextlength}
                                      value={String(guestDefaultData[guestData.fieldid])}
                                      decimalsLimit={2}
                                      disabled={guestData.fieldreadonly && guestData.overridereadonly}
                                      onValueChange={(value, name) => updateGuestData(value, guestData)}
                                  />
                                </IonCol>
                              </IonRow>
                            </IonCol>
                          </IonRow>
                          {errorCheckObj &&
                              <IonRow>
                                <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={size} className="ion-justify-content-left" >
                                  <span id="error">{errorCheckObj.message}</span>
                                </IonCol>
                              </IonRow>
                          }
                        </>
                    )
                  }
                  else if (guestData.fieldcontrol === 5) {
                    return (
                        <>
                          <IonRow>
                            {editIcon}
                            <IonCol style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={size} className="ion-justify-content-left" >
                              <Input type="text"
                                     id={guestData.fieldid}
                                     placeholder={guestData.fieldlabel}
                                     required={guestData.fieldrequired}
                                     minLength={1}
                                     readOnly={guestData.fieldreadonly && guestData.overridereadonly}
                                     max={guestData.fieldtextlength}
                                     value={guestDefaultData[guestData.fieldid]}
                                     onChange={(e) => {updateGuestData(e.target.value, guestData)}}
                                     name={guestData.fieldlabel} style={{width: '100%'}}
                              />
                            </IonCol>
                          </IonRow>
                          {errorCheckObj &&
                              <IonRow>
                                <IonCol no-padding style={{ paddingLeft: "20px", paddingRight: "20px", display: "flex" }} size={size} className="ion-justify-content-left" >
                                  <span id="error">{errorCheckObj.message}</span>
                                </IonCol>
                              </IonRow>
                          }
                        </>
                    )
                  }
                })}
                <IonRow style={{marginBottom: '20px'}}>
                  <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                    <IonButton onClick={() => { setGuestModal({active: false, groupName: '', guestId: '', guestName: ''})}} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                      Cancel
                    </IonButton>
                  </IonCol>
                  <IonCol style={{display: 'flex'}} className="ion-justify-content-center">
                    <IonButton disabled={errorData?.length !== 0} onClick={() => { submitData() }} size="large" style={{ width: '80%' }} expand="block" fill="solid" color={'primary'}>
                      Save
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          </>
      )
    }
    else {
      return (
          <></>
      )
    }

  }


  return (
      <>
          <IonToast
              isOpen={toastState.display}
              onDidDismiss={() => {
                if (toastState.goback) {
                  history.goBack()
                }
                setToastState({
                  display: false,
                  goback: false,
                  message: ''
                })
              }}
              cssClass={'custom-toast'}
              message={toastState.message}
              duration={3000}
          />

      {getGroupRenameModal()}
      {getGuestModal()}
      {getMessageModal()}


        {/*{item?.heading?.length > 0 &&*/}
        {/*    <IonRow>*/}
        {/*      <IonLabel style={{marginLeft: '15px', marginTop: '10px', fontSize: '26px'}}><b>{item.heading}</b></IonLabel>*/}
        {/*    </IonRow>*/}
        {/*}*/}

        {getGroupItems()}
      </>
  )
}

export default Purchaser
